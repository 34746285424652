@import "../../styles/colors";
@import "../../styles/mixins";

$gradient: linear-gradient(to bottom, $true-white, $wintergreen-dream-light);
$trail: url("../../assets/landing/bg-trail-mobile.svg") 50% 55% no-repeat;

.processContainer {
  padding: 5.25rem 2.125rem 1.5rem;
  background: $gradient;

  * {
    font-family: 'Tw Cen MT', sans-serif;
    color: $text-gray-900;
  }

  h2 {
    padding-bottom: 0.25rem;
    margin-bottom: 0;
    font-size: 2rem;
    font-weight: 700;
  }

  .description {
    margin: 0;
    font-size: 1.25rem;
    line-height: 145%;
  }

  .list {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    padding: 3.5rem 0 3.375rem;
    background: $trail;
    background-position-y: -0.15rem;

    .listItem,
    .text,
    .title {
      position: relative;
    }

    .text,
    .title {
      text-align: center;
      z-index: 1;
    }

    .listItem {
      width: 100%;
      margin-bottom: 1.5rem;

      .title {
        margin-bottom: 0.75rem;
        padding: 3.125rem 0 0;
        font-size: 1.375rem;
        font-weight: 500;
      }

      .text {
        font-size: 1.125rem;
        line-height: 130%;
      }

      .number {
        position: absolute;
        top: -0.5rem;
        left: 40%;
        font-weight: 700;
        font-size: 4.375rem;
        font-feature-settings: 'liga' off;
        //opacity: 0.4;

      }

      &:nth-child(1) .number {
        color: $number-one;
      }

      &:nth-child(2) .number {
        color: $number-two;
      }

      &:nth-child(3) .number {
        color: $number-three;
      }

      &:nth-child(4) .number {
        color: $number-four;
      }

      &:nth-child(5) .number {
        color: $number-five;
      }

      &:nth-child(6) .number {
        color: $number-six;
      }

      &:nth-child(7) .number {
        color: $number-seven;
      }

      &:nth-child(8) .number {
        color: $number-eight;
      }
    }
  }

  .getStarted {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
      padding-right: 0.5rem;
      font-size: 1.375rem;
      font-weight: 700;
      color: $wintergreen-dream;
    }
  }
}
