@import "colors";

@mixin smallBlackText {
  font-size: 0.89rem;
  color: $true-black;
}

@mixin focusWithoutOutline {
  &:focus {
    outline: none;
    box-shadow: none !important;
  }
}

@mixin desktopOnlyStyles {
  @media all and (min-width: 993px) {
    @content;
  }
}

@mixin xsDesktopStyles {
  @include desktopOnlyStyles {
    @media all and (max-width: 1279px) {
      @content;
    }
  }
}

@mixin mobileOnlyStyles {
  @media all and (max-width: 992px) {
    @content;
  }
}

@mixin xsMobileStyles {
  @media all and (max-width: 350px) {
    @content;
  }
}

//--Landing--//

@mixin landingImage {
  @include imgPlaceholder540;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  color: $text-black;
}

@mixin imgPlaceholder540 {
  width: 540px;
  height: 540px;
  background: $gray-placeholder;

  * {
    font-size: 1rem;
  }
}

@mixin landingButton {
  padding: 0.75rem 2.125rem;
  background: $wintergreen-dream;
  border-radius: 0.5rem;
  font-family: 'Tw Cen MT', sans-serif;
  font-size: 1.325rem;
  font-weight: 700;
  color: $true-white !important;

  * {
    color: $true-white !important;
  }

  @include mobileOnlyStyles {
    display: inline-block;
    padding: 0.25rem 2.75rem;
    font-size: 0.75rem;
    border-radius: 0.25rem;
  }
}

@mixin landingBold {
  font-weight: 700;
  color: $text-gray-900;

  &:before,
  &:after {
    content: ' ';
  }
}

@mixin landingCircleBg {
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
  background: $text-gray-100;
  border-radius: 50%;

  @include mobileOnlyStyles {
    width: 36px;
    height: 36px;

    img {
      height: 50%;
    }
  }
}

