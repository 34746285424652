@import "colors";
@import "mixins";

:global {
  .card {
    border-color: $barely-white-1;

    .card-header {
      border-left-color: $barely-white-1;
      border-bottom-color: $barely-white-1;
      background-color: $barely-white-2;
    }
  }

  .alert {
    background: $barely-white-2;
    border-color: $barely-white-2;
    color: $teal-primary;

    .alert-heading {
      color: $teal-primary;
      text-align: center;
    }

    p {
      @include smallBlackText;
      text-align: center;
    }
  }

  .input-group-text {
    color: $true-white;
    background: $teal-primary;
  }

  .nav-tabs {
    .nav-item.nav-link {
      color: rgba(0, 0, 0, 0.3);

      &.active {
        color: $teal-primary;
      }
    }
  }

  .btn-group {
    .btn {
      background: $barely-white-2;
      color: rgba(0, 0, 0, 0.3);

      &.active {
        background: $teal-primary;
        color: $true-white;
      }
    }
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;

    &.btn-outline-primary:hover {
      color: $true-white;
    }
  }
}
