@import "styles/colors";
@import "styles/globalStyles";

@font-face {
  font-family: 'Tw Cen MT Condensed';
  src: url("/src/fonts/Tw Cen MT Condensed.ttf");
  font-weight: 400;
}

@font-face {
  font-family: 'Tw Cen MT';
  src: url("/src/fonts/Tw Cen MT Regular.ttf");
  font-weight: 400;
}

html {
  font-size: 112.5%;
  overflow-x: hidden;

  .intercom-app .intercom-lightweight-app-launcher {
    background: #F3FAF7 !important;
  }
}

* {
  color: $teal-primary;
  font-family: 'Tw Cen MT Condensed', sans-serif;
}

$theme-colors: (
        "primary": $teal-primary,
        "secondary": $teal-primary
);

@import "../node_modules/bootstrap/scss/bootstrap";


