$blue--deep-1: #000b40;
$teal--medium-1: #29bba8;
$teal--medium-04: rgba(41, 187, 168, 0.41);
$gray--medium-1: #9e9e9e;

$teal-primary: #8ACDB0;
$teal-primary-025: rgba(138, 205, 176, 0.25);
$barely-white-1: #F5F5F5;
$barely-white-2: #F3FAF7;
$true-black: #000000;
$true-white: #FFFFFF;

$red: #dc3545;
$green: #28a745;

/* Landing page colors */
$gray-placeholder: #D2DDD8;
$text-gray-100: #F4F5F7;
$text-gray-800: #474A57;
$text-gray-900: #18191F;
$text-black: #090808;
$wintergreen-dream: #698C7E;
$wintergreen-dream-light: #DAEEDF;
$wintergreen-dream-dark: #73AA8C;
$wintergreen-dream-dark-1: #4F655C;
$number-one: #A5BAB2;
$number-two: #E0F0E7;
$number-three: #E7F0E0;
$number-four: #F0F0E0;
$number-five: #F0E8E0;
$number-six: #F0E0EC;
$number-seven: #E0E2F0;
$number-eight: #F0E0E0;
$gray-green-dark: #4F655C;
$turquoise-green: #98CDB0;
$magic-mint: #DEF8EC;
$magic-mint-light: #F2FFF9;
$magic-mint-light-1: #f5fef9;
$magic-mint-light-2: #f3fff9;
$magic-mint-light-3: #E0F0E7;
$magic-mint-light-4: #F3FFF9;
$magic-mint-dark: #79A185;

